import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './App.css';
import Home from './Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ServiceDetails from './ServiceDetails/ServiceDetails';
import EMICalculator from './EmiCalculator/EmiCalculator';
import CheckList from './CheckList/CheckList';
import LoanEligibility from './LoanEligibility/LoanEligibility';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Customize the duration of the animations
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/servicedetails" element={<ServiceDetails />} />
        <Route path="/emicalculator" element={<EMICalculator />} />
        <Route path="/checklist" element={<CheckList />} />
        <Route path="/loaneligibility" element={<LoanEligibility />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

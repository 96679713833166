import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => (
    <section id="services" className="services section">

        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p>Services we provide</p>
        </div>{/* End Section Title */}

        <div className="container">

            <div className="row gy-4">

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'offshoring'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-activity"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Off-shoring credit under writing jobs and accounting entry works</h3>
                            </a>
                            <p>Srida undertakes and executes all the credit under-writing works and accounting jobs/functions
                                of organisation enabling....
                            </p>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'DebtConsulting'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-broadcast"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Debt Consulting</h3>
                            </a>
                            <p>Housing Loans, Loan against property, Working Capital Finance, Builder Finance and Project Finance,
                                Unsecured Business Loans, LRD (Loan against rentals) </p>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'Financial'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-easel"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Financial Consulting</h3>
                            </a>
                            <p>Structuring and arranging loans, preparation of project reports, preparation of loan
                                proposals in bank CAM format,  financial CMA preparations, financial reports, ....</p>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'Business'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-bounding-box-circles"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Business Consulting</h3>
                            </a>
                            <p>Srida adds value-based relationships in terms of liaisoning with business suppliers
                                and clients facilitating business opportunities and supply contracts </p>
                            <a href="service-details.html" className="stretched-link"></a>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'Industrial'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-calendar4-week"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Industrial Consulting</h3>
                            </a>
                            <p>We at Srida, provide industrial consulting services like liaisoning with govt. departments
                                for permissions/approvals, TSIIC for land approvals to MSME’s....
                            </p>
                            <a href="service-details.html" className="stretched-link"></a>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                    <div className="service-item position-relative">
                        <Link to='/servicedetails' state={'Collateral'} style={{ color: 'black' }}>
                            <div className="icon">
                                <i className="bi bi-chat-square-text"></i>
                            </div>
                            <a href="service-details.html" className="stretched-link">
                                <h3>Collateral Consulting</h3>
                            </a>
                            <p>We handle the property management services like valuation, legal opinion of the
                                securities provided by the clients for required loan amounts.</p>
                            <a href="service-details.html" className="stretched-link"></a>
                        </Link>
                    </div>
                </div>{/* End Service Item */}

            </div>

        </div>

    </section>
)

export default Services;
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend);

const EMICalculator = () => {
    const [loanAmount, setLoanAmount] = useState(5000000);
    const [rateOfInterest, setRateOfInterest] = useState(10);
    const [tenor, setTenor] = useState(120);
    const [emiAmount, setEmiAmount] = useState(0);
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [parentDropdown, setParentDropdown] = useState(false);
    const [childDropdown, setChildDropdown] = useState(false);

    const mobileNavToggle = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    const mobileNavToggleBtn = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to calculate EMI
    const calculateEMI = (P, R, N) => {
        const monthlyRate = R / 12 / 100; // Monthly interest rate
        const emi = (P * monthlyRate * Math.pow(1 + monthlyRate, N)) / (Math.pow(1 + monthlyRate, N) - 1);
        return emi.toFixed(2);
    };

    // Update EMI whenever inputs change
    useEffect(() => {
        const emi = calculateEMI(loanAmount, rateOfInterest, tenor);
        setEmiAmount(emi);
    }, [loanAmount, rateOfInterest, tenor]);

    const getInterestSpace = () => {
        if (rateOfInterest <= 10) return 10;
        if (rateOfInterest <= 15) return 15;
        if (rateOfInterest <= 20) return 20;
        return 25;
    };

    // Calculate data for the doughnut chart
    const interestSpace = getInterestSpace();
    const chartData = {
        labels: ['EMI Amount', 'Rate of Interest'],
        datasets: [
            {
                label: 'EMI and Interest Breakdown',
                data: [100 - interestSpace, interestSpace],
                backgroundColor: ['#4CAF50', '#FF5733'],
                hoverBackgroundColor: ['#66BB6A', '#FF7043'],
                borderWidth: 1,
            },
        ],
    };

    const footer = () => (
        <footer id="footer" className="footer dark-background">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="#" className="logo d-flex align-items-center">
                                <span className="sitename">SCG</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Srida Consulting Group,</p>
                                <p>D. No. 8-3-1040/201A,</p>
                                <p>Plot. No. 140, Sagar Society</p>
                                <p>Banjara Hills, Hyderabad TS-500073</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+91 99899 27299</span></p>
                                <p><strong>Email:</strong> <span>admin@sridaconsulting.com</span></p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Home</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> About us</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Services</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/emicalculator'> EMICalculator</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/loaneligibility'> LoanEligibility Caliculator</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Off-shoring credit under writing jobs and accounting entry works</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Funding</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Financial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Business Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Industrial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Collateral Consulting</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container text-center">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">SCG</strong> <span>All Rights Reserved</span></p>
                </div>
            </div>

        </footer>
    );

    return (
        <div className={`service-details-page ${isMobileNavActive && 'mobile-nav-active'}`}>

            <header id="header" className="header d-flex align-items-center position-relative">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                    <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
                        <div>
                            <h1 className="sitename" style={{ color: '#ffc451' }}>SCG</h1>
                            <p style={{ fontSize: '7px', color: '#ffffff' }}>Srida Cosultig Group</p>
                        </div>
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><Link to='/'>Home<br /></Link></li>
                            <li><Link to='/'>About</Link></li>
                            <li><Link to='/'>Sectors</Link></li>
                            <li><Link to='/'>Services</Link></li>
                            <li className="dropdown"><a href="#" onClick={() => setParentDropdown(!parentDropdown)}><span>Useful Links</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                <ul className={`${parentDropdown && 'dropdown-active'}`}>
                                    <li><Link to='/loaneligibility' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                        Loan Eligibility Calculator</Link></li>
                                    <li className="dropdown"><a href="#" onClick={() => setChildDropdown(!childDropdown)}><span>CheckList</span> <i
                                        className="bi bi-chevron-down toggle-dropdown"></i></a>
                                        <ul className={`${childDropdown && 'dropdown-active'}`}>
                                            <li><Link to='/checklist' state={'salaried'}>Salaried Employees</Link></li>
                                            <li><Link to='/checklist' state={'business'}>Business Entities</Link></li>
                                            <li><Link to='/checklist' state={'selfemployed'}>Self Employed</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            {/* <li><Link to='/'>Gallery</Link></li>
                            <li><Link to='/'>Team</Link></li> */}
                            <li><Link to='/'>Contact</Link></li>
                        </ul>
                        <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
                            onClick={mobileNavToggle}
                            ref={mobileNavToggleBtn}
                        ></i>
                    </nav>
                </div>
            </header>

            <main className="main">
                {/* Page Title */}
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>EMI Calculator</h1>
                                    {/* <p className="mb-0">EMI Calculator</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><Link to='/'>Home</Link></li>
                                <li className="current">EmiCalculator</li>
                            </ol>
                        </div>
                    </nav>
                </div>{/* End Page Title */}

                {/* Service Details Section */}
                <section id="service-details" className="service-details section">

                    <div className="container">

                        <div className="row gy-5">

                            <div className="col-lg-4 order-2 order-lg-0" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-box">
                                    <h4>EMI Caliculator</h4>
                                    <div className="services-list">
                                        <a className='active' style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Emi Caliculator</span>
                                        </a>
                                    </div>
                                </div>{/* End Services List */}

                                <div className="service-box">
                                    <h4>Loan Eligibility Caliculator</h4>
                                    <div className="services-list">
                                        <Link to='/loaneligibility'>
                                            <i className="bi bi-arrow-right-circle"></i><span>Loan Eligibility Caliculator</span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="help-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-headset help-icon"></i>
                                    <h4>Have a Question?</h4>
                                    <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+91 99899 27299</span></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">admin@sridaconsulting.com</a></p>
                                </div>

                            </div>

                            <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
                                <h3 className='fw-bolder mb-5'>EMI Calculator</h3>

                                <div style={{ margin: '20px 0' }}>
                                    <label className='fs-5'>
                                        <span style={{ color: '#737275', fontWeight: 500 }}>Loan Amount (</span>
                                        <span className='fw-bolder' style={{ color: '#ffc451' }}>₹</span>): {loanAmount}
                                    </label>
                                    <input
                                        type="range"
                                        min="100000"
                                        max="10000000"
                                        step="50000"
                                        value={loanAmount}
                                        onChange={(e) => setLoanAmount(Number(e.target.value))}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div style={{ margin: '20px 0' }}>
                                    <label className='fs-5'>
                                        <span style={{ color: '#737275', fontWeight: 500 }}>Rate of Interest (</span>
                                        <span className='fw-bolder' style={{ color: '#ffc451' }}>%</span>): {rateOfInterest}
                                    </label>
                                    <input
                                        type="range"
                                        min="1"
                                        max="25"
                                        step="0.1"
                                        value={rateOfInterest}
                                        onChange={(e) => setRateOfInterest(Number(e.target.value))}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div style={{ margin: '20px 0' }}>
                                    <label className='fs-5'>
                                        <span style={{ color: '#737275', fontWeight: 500 }}>Tenor in Months: </span>
                                        {tenor}
                                    </label>
                                    <input
                                        type="range"
                                        min="12"
                                        max="360"
                                        step="12"
                                        value={tenor}
                                        onChange={(e) => setTenor(Number(e.target.value))}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div className='my-5'>
                                    <h3 className='fw-bolder'>EMI
                                        <span style={{ color: '#ffc451' }}> (</span>Equated Monthly Installment
                                        <span style={{ color: '#ffc451' }}>)</span>:
                                    </h3>
                                    <p className='fs-3' style={{ color: '#007bff', fontWeight: 500 }}><span className='fw-bolder' style={{ color: '#ffc451' }}>₹</span> {emiAmount}</p>
                                </div>

                                <div style={{ marginTop: '30px', width: '260px', height: '260px', margin: 'auto' }}>
                                    <Doughnut
                                        data={chartData}
                                        options={{
                                            cutout: '50%',
                                            plugins: {
                                                legend: { position: 'bottom' },
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (tooltipItem) {
                                                            if (tooltipItem.label === 'Rate of Interest') {
                                                                return `Rate of Interest: ${rateOfInterest}%`;
                                                            } else {
                                                                return `EMI Amount: ₹${emiAmount}`;
                                                            }
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                </section>{/* /Service Details Section */}

            </main>

            {footer()}
        </div>
    )
};

export default EMICalculator;

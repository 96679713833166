import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const LoanEligibility = () => {
    const [netSalary, setNetSalary] = useState(100000);
    const [loanYears, setLoanYears] = useState(10);
    const [emiPerLakh, setEmiPerLakh] = useState(1267);
    const [loanEligibility, setLoanEligibility] = useState(3394000);
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [parentDropdown, setParentDropdown] = useState(false);
    const [childDropdown, setChildDropdown] = useState(false);
    const [loanCategory, setLoanCategory] = useState('Salaried');

    const interestRate = 9;
    const loanAmount = 100000;

    const mobileNavToggle = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    const mobileNavToggleBtn = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        calculateLoanEligibility();
    }, [netSalary, loanYears, loanCategory]);

    useEffect(() => {
        setNetSalary(100000);
        setLoanYears(10);
    }, [loanCategory])

    function calculateEMI(interestRate, months, loanAmount) {
        const monthlyRate = interestRate / 12 / 100;
        const emi = (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -months));
        return Math.round(emi);
    }

    const calculateLoanEligibility = () => {
        const foir = loanCategory === 'Salaried' ? netSalary * 0.7 :
            loanCategory === 'SelfEmployed' ? netSalary * 0.75 : netSalary * 0.9;
        const existingObligation = 20000;
        const foirLessObligation = foir - existingObligation;

        const emi = calculateEMI(interestRate, loanYears * 12, loanAmount);
        setEmiPerLakh(emi);

        const eligibility = (foirLessObligation / emi) * 100000;
        setLoanEligibility(Math.round(eligibility));
    };

    const handleSalaryChange = (event) => {
        setNetSalary(parseInt(event.target.value, 10));
    };

    const handleYearsChange = (event) => {
        setLoanYears(parseInt(event.target.value, 10));
    };

    const getEmiPercentage = (emi) => {
        if (emi <= 900) return 10;
        if (emi > 900 && emi < 2000) return 15;
        if (emi >= 2000 && emi < 3500) return 20;
        return 25;
    };

    const emiPercentage = getEmiPercentage(emiPerLakh);
    const eligibilityPercentage = 100 - emiPercentage;

    const data = {
        labels: ['EMI per 1 Lakh', 'Loan Eligibility (in Lakh)'],
        datasets: [
            {
                data: [emiPercentage, eligibilityPercentage],
                backgroundColor: ['#4A90E2', '#50C878'],
                hoverBackgroundColor: ['#357ABD', '#3B7E5A'],
                borderWidth: 1,
            },
        ],
    };

    const footer = () => (
        <footer id="footer" className="footer dark-background">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="#" className="logo d-flex align-items-center">
                                <span className="sitename">SCG</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Srida Consulting Group,</p>
                                <p>D. No. 8-3-1040/201A,</p>
                                <p>Plot. No. 140, Sagar Society</p>
                                <p>Banjara Hills, Hyderabad TS-500073</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+91 99899 27299</span></p>
                                <p><strong>Email:</strong> <span>admin@sridaconsulting.com</span></p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Home</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> About us</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Services</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/emicalculator'> EMICalculator</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/loaneligibility'> LoanEligibility Caliculator</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Off-shoring credit under writing jobs and accounting entry works</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Funding</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Financial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Business Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Industrial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Collateral Consulting</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container text-center">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">SCG</strong> <span>All Rights Reserved</span></p>
                </div>
            </div>

        </footer>
    );

    return (
        <div className={`service-details-page ${isMobileNavActive && 'mobile-nav-active'}`}>

            <header id="header" className="header d-flex align-items-center position-relative">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                    <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
                        <div>
                            <h1 className="sitename" style={{ color: '#ffc451' }}>SCG</h1>
                            <p style={{ fontSize: '7px', color: '#ffffff' }}>Srida Cosultig Group</p>
                        </div>
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><Link to='/'>Home<br /></Link></li>
                            <li><Link to='/'>About</Link></li>
                            <li><Link to='/'>Sectors</Link></li>
                            <li><Link to='/'>Services</Link></li>
                            <li className="dropdown"><a href="#" onClick={() => setParentDropdown(!parentDropdown)}><span>Useful Links</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                <ul className={`${parentDropdown && 'dropdown-active'}`}>
                                    <li><Link to='/emicalculator' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                        EmiCalculator</Link></li>
                                    <li className="dropdown"><a href="#" onClick={() => setChildDropdown(!childDropdown)}><span>CheckList</span> <i
                                        className="bi bi-chevron-down toggle-dropdown"></i></a>
                                        <ul className={`${childDropdown && 'dropdown-active'}`}>
                                            <li><Link to='/checklist' state={'salaried'}>Salaried Employees</Link></li>
                                            <li><Link to='/checklist' state={'business'}>Business Entities</Link></li>
                                            <li><Link to='/checklist' state={'selfemployed'}>Self Employed</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            {/* <li><Link to='/'>Gallery</Link></li>
                            <li><Link to='/'>Team</Link></li> */}
                            <li><Link to='/'>Contact</Link></li>
                        </ul>
                        <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
                            onClick={mobileNavToggle}
                            ref={mobileNavToggleBtn}
                        ></i>
                    </nav>
                </div>
            </header>

            <main className="main">
                {/* Page Title */}
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Loan Eligibility Calculator</h1>
                                    {/* <p className="mb-0">EMI Calculator</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><Link to='/'>Home</Link></li>
                                <li className="current">LoanEligibility Calculator</li>
                            </ol>
                        </div>
                    </nav>
                </div>{/* End Page Title */}

                {/* Service Details Section */}
                <section id="service-details" className="service-details section">

                    <div className="container">

                        <div className="row gy-5">

                            <div className="col-lg-4 order-2 order-lg-0" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-box">
                                    <h4>Type of Loan</h4>
                                    <div className="services-list">
                                        <a className={loanCategory === 'Salaried' && 'active'} onClick={() => setLoanCategory('Salaried')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Salaried</span>
                                        </a>
                                        <a className={loanCategory === 'SelfEmployed' && 'active'} onClick={() => setLoanCategory('SelfEmployed')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Self Employed</span>
                                        </a>
                                        <a className={loanCategory === 'Loan against Rentals' && 'active'} onClick={() => setLoanCategory('Loan against Rentals')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Loan against Rentals</span>
                                        </a>
                                    </div>
                                </div>

                                <div className="service-box">
                                    <h4>EMI Caliculator</h4>
                                    <div className="services-list">
                                        <Link to='/emicalculator'>
                                            <i className="bi bi-arrow-right-circle"></i><span>EMI Caliculator</span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="help-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-headset help-icon"></i>
                                    <h4>Have a Question?</h4>
                                    <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+91 99899 27299</span></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">admin@sridaconsulting.com</a></p>
                                </div>

                            </div>

                            <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
                                <h3 className='fw-bolder mb-5'>Loan Eligibility Calculator -
                                    <span style={{ color: '#ffc451' }}> {loanCategory}</span>
                                </h3>
                                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                    <label className='fs-5'>
                                        <span style={{ color: '#737275', fontWeight: 500 }}>Net Salary: </span>
                                        <span className='fw-bolder' style={{ color: '#ffc451' }}>₹</span> {netSalary.toLocaleString()}
                                    </label>
                                    <input
                                        type="range"
                                        min="50000"
                                        max="1000000"
                                        step="10000"
                                        value={netSalary}
                                        onChange={handleSalaryChange}
                                        style={{ width: '100%', margin: '10px 0' }}
                                    />

                                    <label className='fs-5'>
                                        <span style={{ color: '#737275', fontWeight: 500 }}>Loan Tenure: </span>
                                        {loanYears}
                                    </label>
                                    <input
                                        type="range"
                                        min="2"
                                        max="20"
                                        step="1"
                                        value={loanYears}
                                        onChange={handleYearsChange}
                                        style={{ width: '100%', margin: '10px 0' }}
                                    />
                                </div>

                                <div className='my-5'>
                                    <p className='fs-5'> <span style={{ color: '#737275', fontWeight: 500 }}>EMI per 1 Lakh: </span>
                                        <span className='fw-bolder' style={{ color: '#ffc451' }}>₹ </span>{emiPerLakh}
                                    </p>
                                    <p className='fs-5'> <span style={{ color: '#737275', fontWeight: 500 }}>Loan Eligibility: </span>
                                        <span className='fw-bolder' style={{ color: '#ffc451' }}>₹ </span>{(loanEligibility / 100000).toFixed(2)} Lakh
                                    </p>
                                </div>

                                <div style={{ maxWidth: '350px', height: '300px', width: '300px', margin: '0 auto' }}>
                                    <Pie
                                        data={data}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'bottom',
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (context) {
                                                            return `${context.label}: ${context.raw.toFixed(2)}%`;
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

            </main>

            {footer()}
        </div>


    );
};

export default LoanEligibility;

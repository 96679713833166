import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import axios from 'axios';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import Services from './Services';

const Home = () => {
    const [activeSection, setActiveSection] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [typeOfLoan, setTypeOfLoan] = useState('');
    const [loanAmount, setLoanAmount] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [numberError, setNumberError] = useState(false);
    const [typoOfLoanError, setTypeOfLoanError] = useState(false);
    const [loanAmountError, setLoanAmountError] = useState(false);
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [parentDropdown, setParentDropdown] = useState(false);
    const [childDropdown, setChildDropdown] = useState(false);

    const mobileNavToggle = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    const mobileNavToggleBtn = useRef(null);

    const sendMail = async (e) => {
        e.preventDefault();

        if (name.length >= 3 && email.length >= 9 && number.length === 10 && typeOfLoan.length >= 2 && loanAmount.length >= 5) {
            setNameError(false);
            setEmailError(false);
            setNumberError(false);
            setTypeOfLoanError(false);
            setLoanAmountError(false);

            setName('');
            setEmail('');
            setNumber('');
            setTypeOfLoan('');
            setLoanAmount('');

            const serviceId = 'service_clskv4o';
            const templateId = 'template_iqh5po8';
            const publickey = 'juE0JgOqTDlep-rU6';

            const template_params = {
                sender_name: 'Vamshi',
                sender_email: 'vamshireddy.manda29@gmail.com',
                sender_number: '8688749848',
                type_of_loan: 'personal',
                loan_amount: '5000000'
            };

            console.log("Comming In success");

            emailjs.send(serviceId, templateId, template_params, publickey)
                .then((response) => {
                    console.log('Email sent successfully: ', response);
                })
                .catch((error) => {
                    console.log('error sending email: ', error);
                })
        } else {
            if (name.length < 3) {
                setNameError(true);
            } else {
                setNameError(false);
            } if (email.length < 9) {
                setEmailError(true);
            } else {
                setEmailError(false);
            } if (number.length !== 10) {
                setNumberError(true);
            } else {
                setNumberError(false);
            } if (typeOfLoan.length < 2) {
                setTypeOfLoanError(true);
            } else {
                setTypeOfLoanError(false);
            } if (loanAmount.length < 5) {
                setLoanAmountError(true);
            } else {
                setLoanAmountError(false);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentSection = '';

            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (window.scrollY >= sectionTop - 100 && window.scrollY < sectionTop + sectionHeight) {
                    currentSection = section.getAttribute('id');
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const scrollTop = document.querySelector('.scroll-top');

        function toggleScrollTop() {
            if (scrollTop) {
                window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
            }
        }

        // Add scroll event listener
        window.addEventListener('scroll', toggleScrollTop);
        window.addEventListener('load', toggleScrollTop);

        // Scroll to top smoothly when the button is clicked
        if (scrollTop) {
            scrollTop.addEventListener('click', (e) => {
                e.preventDefault();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('scroll', toggleScrollTop);
            window.removeEventListener('load', toggleScrollTop);
            if (scrollTop) {
                scrollTop.removeEventListener('click', (e) => {
                    e.preventDefault();
                });
            }
        };
    }, []);

    useEffect(() => {
        const selectBody = document.querySelector('.index-page');
        const selectHeader = document.querySelector('#header');

        function toggleScrolled() {
            if (
                selectHeader.classList.contains('scroll-up-sticky') ||
                selectHeader.classList.contains('sticky-top') ||
                selectHeader.classList.contains('fixed-top')
            ) {
                window.scrollY > 100
                    ? selectBody.classList.add('scrolled')
                    : selectBody.classList.remove('scrolled');
            }
        }

        window.addEventListener('scroll', toggleScrolled);
        window.addEventListener('load', toggleScrolled);

        return () => {
            window.removeEventListener('scroll', toggleScrolled);
            window.removeEventListener('load', toggleScrolled);
        };
    }, []);

    // Preloader (loading)
    useEffect(() => {
        const preloader = document.getElementById('preloader');
        if (preloader) {
            preloader.style.display = 'none';  // Or you can use preloader.remove() if you want to completely remove it
        }
    }, []);

    const swiperConfig = {
        loop: true,
        speed: 900,
        autoplay: {
            delay: 1000,
        },
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 80,
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 120,
            },
        },
    };

    const swiperConfigTestimonials = {
        loop: true,
        speed: 600,
        autoplay: {
            delay: 5000,
        },
        slidesPerView: 'auto',
        pagination: {
            clickable: true,
        },
    };

    const header = () => (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
                    <div>
                        <h1 className="sitename" style={{ color: '#ffc451' }}>SCG</h1>
                        <p style={{ fontSize: '7px', color: '#ffffff' }}>Srida Cosultig Group</p>
                    </div>
                </a>

                <nav id="navmenu" className="navmenu">
                    <ul>
                        <li><a href="#hero" className={activeSection === 'hero' ? 'active' : ''}
                            onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>Home<br /></a></li>
                        <li><a href="#about" className={activeSection === 'about' ? 'active' : ''}
                            onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>About</a></li>
                        <li><a href="#sectors" className={activeSection === 'sectors' ? 'active' : ''}
                            onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>Sectors</a></li>
                        <li><a href="#services" className={activeSection === 'services' ? 'active' : ''}
                            onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>Services</a></li>
                        {/* <li><Link to='/emicalculator'>EmiCalculator</Link></li>                         */}
                        <li className="dropdown"><a href="#" onClick={() => setParentDropdown(!parentDropdown)}><span>Useful Links</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                            <ul className={`${parentDropdown && 'dropdown-active'}`}>
                                <li><Link to='/emicalculator' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                    EmiCalculator</Link></li>
                                <li><Link to='/loaneligibility' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                    Loan Eligibility Calculator</Link></li>
                                <li className="dropdown"><a href="#" onClick={() => setChildDropdown(!childDropdown)}><span>CheckList</span> <i
                                    className="bi bi-chevron-down toggle-dropdown"></i></a>
                                    <ul className={`${childDropdown && 'dropdown-active'}`}>
                                        <li><Link to='/checklist' state={'salaried'} onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                            Salaried Employees</Link></li>
                                        <li><Link to='/checklist' state={'business'} onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                            Business Entities</Link></li>
                                        <li><Link to='/checklist' state={'selfemployed'} onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                            Self Employed</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {/* <li><a href="#gallery" className={activeSection === 'gallery' ? 'active' : ''}>Gallery</a></li>
                        <li><a href="#team" className={activeSection === 'team' ? 'active' : ''}>Team</a></li> */}
                        <li><a href="#contact" className={activeSection === 'contact' ? 'active' : ''} 
                            onClick={() => {setIsMobileNavActive(!isMobileNavActive)}}>Contact</a></li>
                    </ul>
                    <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
                        onClick={mobileNavToggle}
                        ref={mobileNavToggleBtn}
                    ></i>
                </nav>
            </div>
        </header>
    );

    const hero = () => (
        <section id="hero" className="hero section dark-background">

            {/* <img src="assets/img/background/moneyplant3.jpeg" alt="" data-aos="fade-in" /> */}

            <img src="assets/img/background/office1-min.jpg" alt="" />

            <div className="container">

                <div className="row justify-content-center text-center" data-aos="fade-up" >
                    <div className="col-xl-6 col-lg-8">
                        <h2 style={{ fontSize: '100px', fontWeight: 'bold' }} data-aos="fade-up" data-aos-delay="200"><span>SRIDA</span> </h2>
                        <h4 style={{ fontSize: '40px' }} data-aos="fade-up" data-aos-delay="600">YOUR FINANCIAL PARTNER</h4>
                    </div>
                </div>
            </div>

        </section>
    );

    const about = () => (
        <section id="about" className="about section">

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="row gy-4">
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src="assets/img/office1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 order-2 order-lg-1 content">
                        <h3>Srida consulting Group</h3>
                        <p className="fst-italic">
                            Srida is a value based and professional enterprises run and managed by consolidated
                            experience of around 45 years in banking and finance industry.
                        </p>
                        <h5>Our Expertise</h5>
                        <ul>
                            <li><i className="bi bi-check2-all"></i> <span>Retail Loans such as Home Loan, Mortgage Loan, Unsecured
                                business loans at the right time</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Arrangement of complicated loan probabilities</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Structuring of working capital finance</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Funding to low income group</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Structuring & arranging project finances</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Arranging finances for "Green Field Projects"</span></li>
                            <li><i className="bi bi-check2-all"></i> <span>Undertaking credit under writing and accounting off-shore
                                works</span></li>
                        </ul>
                    </div>
                </div>

            </div>

        </section>
    );

    const clients = () => (
        <section id="clients" className="clients section">

            <div className="container section-title" data-aos="fade-up">
                <h3>Banks and Financial Institutions</h3>
                <p>we are partnered with</p>
            </div>

            <Swiper
                {...swiperConfig} // Spread the configuration object
                modules={[Pagination, Autoplay]} // Import modules                        
            >
                <SwiperSlide className='align-self-center'><img src={`assets/img/partners/axisbank.jpg`} className="img-fluid" alt="Client 1" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src={`assets/img/partners/bajajfinance.jpg`} className="img-fluid" alt="Client 3" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src={`assets/img/partners/idbibank.jpg`} className="img-fluid" alt="Client 4" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/axisfin.png" className="img-fluid" alt="Client 2" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src={`assets/img/partners/karnatakabank.jpg`} className="img-fluid" alt="Client 5" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src={`assets/img/partners/sbmbank.jpg`} className="img-fluid" alt="Client 6" /></SwiperSlide>

                {/* Pagination */}
                {/* <div className="swiper-pagination"></div> */}
            </Swiper>

            <Swiper
                {...swiperConfig} // Spread the configuration object
                modules={[Pagination, Autoplay]} // Import modules                        
            >
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/unionbank.png" className="img-fluid" alt="Client 1" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/hdfc.png" className="img-fluid" alt="Client 3" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/kotak.png" className="img-fluid" alt="Client 4" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/kvb.png" className="img-fluid" alt="Client 2" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/bandhan.jpg" className="img-fluid" alt="Client 5" /></SwiperSlide>
                <SwiperSlide className='align-self-center'><img src="assets/img/partners/icici.png" className="img-fluid" alt="Client 6" /></SwiperSlide>

                {/* Pagination */}
                {/* <div className="swiper-pagination"></div> */}
            </Swiper>

        </section>
    );

    const footer = () => (
        <footer id="footer" className="footer dark-background">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="#" className="logo d-flex align-items-center">
                                <span className="sitename">SCG</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Srida Consulting Group,</p>
                                <p>D. No. 8-3-1040/201A,</p>
                                <p>Plot. No. 140, Sagar Society</p>
                                <p>Banjara Hills, Hyderabad TS-500073</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+91 99899 27299</span></p>
                                <p><strong>Email:</strong> <span>admin@sridaconsulting.com</span></p>
                            </div>
                            {/* <div className="social-links d-flex mt-4">
                                <a><i className="bi bi-twitter-x"></i></a>
                                <a><i className="bi bi-facebook"></i></a>
                                <a><i className="bi bi-instagram"></i></a>
                                <a><i className="bi bi-linkedin"></i></a>
                            </div> */}
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href="#"> Home</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#about"> About us</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/emicalculator'> EMICalculator</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/loaneligibility'> LoanEligibility Caliculator</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href='#services'> Off-0shoring credit under writing jobs and accounting entry works</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Debt Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Financial Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Business Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Industrial Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href="#services"> Collateral Consulting</a></li>
                            </ul>
                        </div>

                        {/* <div className="col-lg-4 col-md-12 footer-newsletter">
                                <h4>Our Newsletter</h4>
                                <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
                                <form action="forms/newsletter.php" method="post" className="php-email-form">
                                    <div className="newsletter-form"><input type="email" name="email" /><input type="submit" value="Subscribe" />
                                    </div>
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Your subscription request has been sent. Thank you!</div>
                                </form>
                            </div> */}

                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container text-center">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">SCG</strong> <span>All Rights Reserved</span></p>
                </div>
            </div>

        </footer>
    );

    const portfolio = () => (
        <section id="gallery" className="portfolio section">

            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
                <h2>Gallery</h2>
                <p>Check our Gallery</p>
            </div>{/* End Section Title */}

            <div className="container">

                <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">

                    {/* <ul className="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
                                <li data-filter="*" className="filter-active">All</li>
                                <li data-filter=".filter-app">App</li>
                                <li data-filter=".filter-product">Card</li>
                                <li data-filter=".filter-branding">Web</li>
                            </ul> */}

                    <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                        <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app" data-aos="fade-up" data-aos-delay="200">
                            <img src="assets/img/background/honour1.jpeg" className="img-fluid" alt="" />
                        </div>{/* End Portfolio Item */}

                        <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                            <img src="assets/img/background/scgoffice3.jpeg" className="img-fluid" alt="" data-aos="fade-up" data-aos-delay="400" />

                            <img src="assets/img/background/shyam4.jpeg" className="img-fluid mt-4" alt="" data-aos="fade-up" data-aos-delay="500" />
                        </div>{/* End Portfolio Item */}

                        <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding" data-aos="fade-up" data-aos-delay="400">
                            <img src="assets/img/background/honour2.jpeg" className="img-fluid" alt="" />
                        </div>{/* End Portfolio Item */}

                    </div>{/* End Portfolio Container */}

                </div>

            </div>

        </section>
    );

    const team = () => (
        <section id="team" className="team section">

            {/* Section Title */}
            <div className="container section-title" data-aos="fade-up">
                <h2>Team</h2>
                <p>our Team</p>
            </div>{/* End Section Title */}

            <div className="container">

                <div className="row gy-4">

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="team-member">
                            <div className="member-img">
                                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <a><i className="bi bi-twitter-x"></i></a>
                                    <a><i className="bi bi-facebook"></i></a>
                                    <a><i className="bi bi-instagram"></i></a>
                                    <a><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Walter White</h4>
                                <span>Chief Executive Officer</span>
                            </div>
                        </div>
                    </div>{/* End Team Member */}

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                        <div className="team-member">
                            <div className="member-img">
                                <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <a><i className="bi bi-twitter-x"></i></a>
                                    <a><i className="bi bi-facebook"></i></a>
                                    <a><i className="bi bi-instagram"></i></a>
                                    <a><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Sarah Jhonson</h4>
                                <span>Product Manager</span>
                            </div>
                        </div>
                    </div>{/* End Team Member */}

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                        <div className="team-member">
                            <div className="member-img">
                                <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <a><i className="bi bi-twitter-x"></i></a>
                                    <a><i className="bi bi-facebook"></i></a>
                                    <a><i className="bi bi-instagram"></i></a>
                                    <a><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>William Anderson</h4>
                                <span>CTO</span>
                            </div>
                        </div>
                    </div>{/* End Team Member */}

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
                        <div className="team-member">
                            <div className="member-img">
                                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <a><i className="bi bi-twitter-x"></i></a>
                                    <a><i className="bi bi-facebook"></i></a>
                                    <a><i className="bi bi-instagram"></i></a>
                                    <a><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Amanda Jepson</h4>
                                <span>Accountant</span>
                            </div>
                        </div>
                    </div>{/* End Team Member */}

                </div>

            </div>

        </section>
    );

    const testimonials = () => (
        <section id="testimonials" className="testimonials section dark-background">

            <img src="assets/img/background/office3.jpg" className="testimonials-bg" alt="" />

            <div className="container" data-aos="fade-up" data-aos-delay="100">

                <div className="swiper init-swiper">
                    <Swiper {...swiperConfigTestimonials} modules={[Pagination, Autoplay]}>
                        <SwiperSlide>
                            <div className="testimonial-item">
                                <img src="/assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="Saul Goodman" />
                                <h3>Saul Goodman</h3>
                                <h4>Ceo &amp; Founder</h4>
                                <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    <span>Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.</span>
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </SwiperSlide>
                        {/* End testimonial item */}

                        <SwiperSlide>
                            <div className="testimonial-item">
                                <img src="/assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="Sara Wilsson" />
                                <h3>Sara Wilsson</h3>
                                <h4>Designer</h4>
                                <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    <span>Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.</span>
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </SwiperSlide>
                        {/* End testimonial item */}

                        <SwiperSlide>
                            <div className="testimonial-item">
                                <img src="/assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="Jena Karlis" />
                                <h3>Jena Karlis</h3>
                                <h4>Store Owner</h4>
                                <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    <span>Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.</span>
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </SwiperSlide>
                        {/* End testimonial item */}

                        <SwiperSlide>
                            <div className="testimonial-item">
                                <img src="/assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="Matt Brandon" />
                                <h3>Matt Brandon</h3>
                                <h4>Freelancer</h4>
                                <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    <span>Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.</span>
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </SwiperSlide>
                        {/* End testimonial item */}

                        <SwiperSlide>
                            <div className="testimonial-item">
                                <img src="/assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="John Larson" />
                                <h3>John Larson</h3>
                                <h4>Entrepreneur</h4>
                                <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                </div>
                                <p>
                                    <i className="bi bi-quote quote-icon-left"></i>
                                    <span>Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.</span>
                                    <i className="bi bi-quote quote-icon-right"></i>
                                </p>
                            </div>
                        </SwiperSlide>
                        {/* End testimonial item */}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </div>

            </div>

        </section>
    );

    return (
        <div className={`index-page ${isMobileNavActive && 'mobile-nav-active'}`}>

            {header()}

            <main className="main">

                {hero()}

                {about()}

                {clients()}

                {/* Features Section */}
                <section id="sectors" className="features section">

                    <div className="container">

                        <div className="container section-title" data-aos="fade-up">
                            <h2>Major sectors</h2>
                            <p>we deal with</p>
                        </div>

                        <div className="row gy-4">
                            <div className="features-image col-lg-6" data-aos="fade-up" data-aos-delay="100"><img
                                src="assets/img/features-bg.jpg" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex flex-column">

                                <div className="features-item d-flex align-items-center ps-0 ps-lg-3 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-hospital flex-shrink-0"></i>
                                    <div>
                                        <h4>Pharmaceutical Sector</h4>
                                        <p>We cater to MSME’s who deal with chemicals, pharma intermediates, API’s for both domestic and export-based operations</p>
                                    </div>
                                </div>{/* End Features Item*/}

                                <div className="features-item d-flex align-items-center mt-5 ps-0 ps-lg-3" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-pc-display-horizontal flex-shrink-0"></i>
                                    <div>
                                        <h4>IT & Software Sector</h4>
                                        <p>Deal with entities which are into software products and services, and into I.T/Networking products and
                                            peripherals catering to private, govt. departments and others.</p>
                                    </div>
                                </div>{/* End Features Item*/}

                                <div className="features-item d-flex align-items-center mt-5 ps-0 ps-lg-3" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-broadcast flex-shrink-0"></i>
                                    <div>
                                        <h4>Engineering Sector</h4>
                                        <p>Deal with MSME’s engaged in engineering-based products and services rendered to private and public enterprises</p>
                                    </div>
                                </div>{/* End Features Item*/}

                                <div className="features-item d-flex align-items-center mt-5 ps-0 ps-lg-3" data-aos="fade-up" data-aos-delay="500">
                                    <i className="bi bi-buildings flex-shrink-0"></i>
                                    <div>
                                        <h4>Real Estate</h4>
                                        <p>We deal with project builders, real estate builders for project approvals at multiple banks, and property valuations and obtaining legal opinions </p>
                                    </div>
                                </div>{/* End Features Item*/}

                                <div className="features-item d-flex align-items-center mt-5 ps-0 ps-lg-3" data-aos="fade-up" data-aos-delay="600">
                                    <i className="bi bi-receipt-cutoff flex-shrink-0"></i>
                                    <div>
                                        <h4>Retail and Wholesale Traders</h4>
                                        <p>All the traders which include retailers, wholesalers, stockists, and other distribution channels </p>
                                    </div>
                                </div>{/* End Features Item*/}

                                <div className="features-item d-flex align-items-center mt-5 ps-0 ps-lg-3" data-aos="fade-up" data-aos-delay="700">
                                    <i className="bi bi-camera-reels flex-shrink-0"></i>
                                    <div>
                                        <h4>Other Sectors and project-based funding</h4>
                                        <p>Other service-based professionals, special and niche products and industries </p>
                                    </div>
                                </div>{/* End Features Item*/}

                            </div>
                        </div>

                    </div>

                </section>{/* /Features Section */}

                <Services />

                {/* Call To Action Section */}
                <section id="call-to-action" className="call-to-action section dark-background">

                    <img src="assets/img/background/office2.jpg" alt="" />

                    <div className="container">
                        <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                            <div className="col-xl-10">
                                <div className="text-center">
                                    <h3>Call To Action</h3>
                                    <p>For Further information regarding our services connect with us.</p>
                                    <a className="cta-btn" href="#addressForm">Call To Action</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>{/* /Call To Action Section */}

                {/* Contact Section */}
                <section id="contact" className="contact section">

                    {/* Section Title */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p>Contact Us</p>
                    </div>{/* End Section Title */}

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="mb-4" data-aos="fade-up" data-aos-delay="200">
                            {/* <iframe style="border:0; width: 100%; height: 270px;"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus"
                                frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            <iframe style={{ border: '0', width: '100%', height: '270px' }}
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15226.564945938295!2d78.4393894!3d17.4289966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90d2432dc115%3A0x2819ee39b93ed6ca!2sTomar%20House!5e0!3m2!1sen!2sin!4v1728829410834!5m2!1sen!2sin"
                                allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>{/* End Google Maps */}

                        <div id='addressForm' className="row gy-4">

                            <div className="col-lg-4">
                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                                    <div>
                                        <h3>Address</h3>
                                        <p>
                                            D. No. 8-3-1040/201A,
                                            Plot. No. 140, Sagar Society
                                            Banjara Hills, Hyderabad TS-500073
                                        </p>
                                    </div>
                                </div>{/* End Info Item */}

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-telephone flex-shrink-0"></i>
                                    <div>
                                        <h3>Call Us</h3>
                                        <p>+91 99899 27299</p>
                                    </div>
                                </div>{/* End Info Item */}

                                <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="500">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email Us</h3>
                                        <p>admin@sridaconsulting.com</p>
                                    </div>
                                </div>{/* End Info Item */}

                            </div>

                            <div className="col-lg-8 mt-5" id='calltoaction'>
                                <form className="php-email-form" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="row gy-4">

                                        <div className="col-md-6">
                                            <input type="text" name="name" className="form-control" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                            {nameError && (
                                                <span className='text-danger'>Please enter your name *</span>
                                            )}
                                        </div>

                                        <div className="col-md-6 ">
                                            <input type="email" className="form-control" name="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            {emailError && (
                                                <span className='text-danger'>Please enter your email address *</span>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <input type="text" className="form-control" name="mobilenumber" placeholder="Mobile Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                                            {numberError && (
                                                <span className='text-danger'>Please enter 10 digit mobile number *</span>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <input type="text" className="form-control" name="typeofloan" placeholder="Type of Loan" value={typeOfLoan} onChange={(e) => setTypeOfLoan(e.target.value)} />
                                            {typoOfLoanError && (
                                                <span className='text-danger'>Please enter type of loan *</span>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <input type="text" className="form-control" name="loanamount" placeholder="Loan Amount" value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} />
                                            {loanAmountError && (
                                                <span className='text-danger'>Please enter valid amount *</span>
                                            )}
                                        </div>

                                        <div className="col-md-12 text-center">
                                            {/* <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div> */}

                                            <button type="submit" onClick={(e) => sendMail(e)}>Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>{/* End Contact Form */}

                        </div>

                    </div>

                </section>{/* /Contact Section */}

            </main>

            {footer()}

            {/* Scroll Top */}
            <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>

        </div>
    )
};

export default Home;
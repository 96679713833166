import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const CheckList = () => {
    const location = useLocation();
    const prop = location.state || '';
    const [checkValue, setCheckValue] = useState(prop);
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [parentDropdown, setParentDropdown] = useState(false);

    const mobileNavToggle = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    const mobileNavToggleBtn = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (prop === '') {
            setCheckValue('salaried');
        }
    }, []);

    const salaried = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h2>CheckList for Salaried Employees</h2>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>KYC (Aadhar & PAN)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 months payslips</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 6 months bank statements (Salary Credit)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Official e-mail id and personal mail-id</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years form-16</span>
                </li>
            </ul>

            <h4>Property Documents</h4>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Agreement of Sale (Applicable for Housing loan)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Sale Deed (Applicable for Mortgage loan)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Link Documents</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Latest Property tax receipt & EC</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Building Sanction plan and permission copy</span>
                </li>
            </ul>
        </div>
    );

    const business = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h2>CheckList for Business Entities</h2>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>KYC of the firm (GST, Labour/Trade License/Product licenses)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Udyam Certificate of the entity</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years IT returns of the entity (Full set of financials)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 12 months GST returns of the entity</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Sanction letters of all the existing loans of the entity</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 12 months bank statements of all the bank accounts of the entity</span>
                </li>
            </ul>

            <h4>Additional documents required in case of  partnership firm</h4>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Partnership Deed and PAN copy of the firm</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years individual IT returns of the partners</span>
                </li>
            </ul>

            <h4>Additional documents required in case of Private limited/Limited companies</h4>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Articles of Association</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Memorandum of Association</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Certificate of Incorporation</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>PAN Copy</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years individual IT returns of the directors</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Latest list of directors and share holding of the company</span>
                </li>
            </ul>
        </div>
    );

    const selfemployed = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h2>Checklist for Self-employed individuals</h2>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>KYC (Aadhar & PAN)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years IT returns (Saral copy & Computation)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 6 months bank statements</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Last 3 years Form-26AS</span>
                </li>
            </ul>

            <h4>Property Documents</h4>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Agreement of Sale (Applicable for Housing loan)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Sale Deed (Applicable for Mortgage loan)</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Link Documents</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Latest Property tax receipt & EC</span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-center'>
                    <i className="bi bi-check-circle"></i>
                    <span>Building Sanction plan and permission copy</span>
                </li>
            </ul>
        </div>
    );

    const footer = () => (
        <footer id="footer" className="footer dark-background">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="#" className="logo d-flex align-items-center">
                                <span className="sitename">SCG</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Srida Consulting Group,</p>
                                <p>D. No. 8-3-1040/201A,</p>
                                <p>Plot. No. 140, Sagar Society</p>
                                <p>Banjara Hills, Hyderabad TS-500073</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+91 99899 27299</span></p>
                                <p><strong>Email:</strong> <span>admin@sridaconsulting.com</span></p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Home</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> About us</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Services</a></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/emicalculator'> EMICalculator</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/loaneligibility'> LoanEligibility Caliculator</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Off-shoring credit under writing jobs and accounting entry works</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Funding</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Financial Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Business Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Industrial Consulting</a></li>
                                <li><i className="bi bi-chevron-right"></i> <a href='/'> Collateral Consulting</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container text-center">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">SCG</strong> <span>All Rights Reserved</span></p>
                </div>
            </div>

        </footer>
    );

    return (
        <div className={`service-details-page ${isMobileNavActive && 'mobile-nav-active'}`}>

            <header id="header" className="header d-flex align-items-center position-relative">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                    <a className="logo d-flex align-items-center me-auto me-lg-0">
                        <Link to='/'>
                            <h1 className="sitename" style={{ color: '#ffc451' }}>SCG</h1>
                            <p style={{ fontSize: '7px', color: '#ffffff' }}>Srida Cosultig Group</p>
                        </Link>
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><Link to='/'>Home<br /></Link></li>
                            <li><Link to='/'>About</Link></li>
                            <li><Link to='/'>Sectors</Link></li>
                            <li><Link to='/'>Services</Link></li>
                            <li className="dropdown"><a href="#" onClick={() => setParentDropdown(!parentDropdown)}><span>Useful Links</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                <ul className={`${parentDropdown && 'dropdown-active'}`}>
                                    <li><Link to='/emicalculator'>EmiCalculator</Link></li>
                                    <li><Link to='/loaneligibility' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                        Loan Eligibility Calculator</Link></li>
                                    {/* <li className="dropdown"><a href="#"><span>CheckList</span> <i
                                        className="bi bi-chevron-down toggle-dropdown"></i></a>
                                        <ul>
                                            <li><Link to='/checklist' state={'salaried'}>Salaried Employees</Link></li>
                                            <li><Link to='/checklist' state={'business'}>Business Entities</Link></li>
                                            <li><Link to='/checklist' state={'selfemployed'}>Self Employed</Link></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </li>
                            {/* <li><Link to='/'>Gallery</Link></li>
                            <li><Link to='/'>Team</Link></li> */}
                            <li><Link to='/'>Contact</Link></li>
                        </ul>
                        <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
                            onClick={mobileNavToggle}
                            ref={mobileNavToggleBtn}
                        ></i>
                    </nav>
                </div>
            </header>

            <main className="main">
                {/* Page Title */}
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Check Lists</h1>
                                    <p className="mb-0">{checkValue}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><Link to='/'>Home</Link></li>
                                <li className="current">Service Details</li>
                            </ol>
                        </div>
                    </nav>
                </div>{/* End Page Title */}

                {/* Service Details Section */}
                <section id="service-details" className="service-details section">

                    <div className="container">

                        <div className="row gy-5">

                            <div className="col-lg-4 order-2 order-lg-0" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-box">
                                    <h4>Check List</h4>
                                    <div className="services-list">
                                        <a className={checkValue === 'salaried' && 'active'} onClick={() => setCheckValue('salaried')}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Salaried Employees</span>
                                        </a>
                                        <a className={checkValue === 'business' && 'active'} onClick={() => setCheckValue('business')}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Bsiness Entities</span>
                                        </a>
                                        <a className={checkValue === 'selfemployed' && 'active'} onClick={() => setCheckValue('selfemployed')}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Self Employed Individuals</span>
                                        </a>
                                    </div>
                                </div>{/* End Services List */}

                                <div className="help-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-headset help-icon"></i>
                                    <h4>Have a Question?</h4>
                                    <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+91 99899 27299</span></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">admin@sridaconsulting.com</a></p>
                                </div>

                            </div>

                            {checkValue === 'salaried' ? (
                                salaried()
                            ) : checkValue === 'business' ? (
                                business()
                            ) : selfemployed()
                            }

                        </div>

                    </div>

                </section>{/* /Service Details Section */}

            </main>

            {footer()}
        </div>
    )

};

export default CheckList;
import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ServiceDetails = () => {
    const location = useLocation();
    const prop = location.state || '';
    const [service, setService] = useState(prop);
    const [head, setHead] = useState('');
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const [parentDropdown, setParentDropdown] = useState(false);
    const [childDropdown, setChildDropdown] = useState(false);

    const mobileNavToggle = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    const mobileNavToggleBtn = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (prop === '') {
            setService('offshoring');
        }
    }, []);

    useEffect(() => {
        if (service === 'offshoring') {
            setHead('Off-0shoring credit under writing jobs and accounting entry works');
        } else if (service === 'DebtConsulting') {
            setHead('Debt Consulting');
        } else if (service === 'Financial') {
            setHead('Financial Consulting');
        } else if (service === 'Business') {
            setHead('Business Consulting');
        } else if (service === 'Industrial') {
            setHead('Industrial Consulting');
        } else {
            setHead('Collateral Consulting');
        }
    }, [service]);

    const footer = () => (
        <footer id="footer" className="footer dark-background">

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <a href="#" className="logo d-flex align-items-center">
                                <span className="sitename">SCG</span>
                            </a>
                            <div className="footer-contact pt-3">
                                <p>Srida Consulting Group,</p>
                                <p>D. No. 8-3-1040/201A,</p>
                                <p>Plot. No. 140, Sagar Society</p>
                                <p>Banjara Hills, Hyderabad TS-500073</p>
                                <p className="mt-3"><strong>Phone:</strong> <span>+91 99899 27299</span></p>
                                <p><strong>Email:</strong> <span>admin@sridaconsulting.com</span></p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-3 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Home</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> About us</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Services</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/emicalculator'> EMICalculator</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/loaneligibility'> LoanEligibility Caliculator</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-6 col-md-3 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Off-shoring credit under writing jobs and accounting entry works</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Funding</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Financial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Business Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Industrial Consulting</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to='/'> Collateral Consulting</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <div className="container text-center">
                    <p>© <span>Copyright</span> <strong className="px-1 sitename">SCG</strong> <span>All Rights Reserved</span></p>
                </div>
            </div>

        </footer>
    );

    const offshoring = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/offshoring.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <p className='mt-3'>
                Srida undertakes and executes all the credit under-writing works and accounting jobs/functions of organisation enabling the business houses/entities to concentrate more on their core functions and execute their support functional activities like accounting and finance related functions with professional standards and error free practises.
            </p>
        </div>
    )

    const funding = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/loans.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <ul>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>Housing Loans: </strong> We cater to housing loans to salaried, self-employed, NRI, based on income and without-income methods
                    </span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>Loan against property: </strong> We provide loans against residential/commercial constructed properties, self-occupied industrial properties, and urban vacant lands to individuals, firms and companies based on income and without-income methods
                    </span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>Working Capital Finance: </strong> Structuring and arranging working capital limits such as fund-based limits like Cash Credit, Overdraft, term loans, FCNR (Foreign Currency loans),
                        Channel finance and non-fund based limits like Letter of Credit, Bank Guarantees, Forward Contracts against collateral securities, without security under CGTMSE Schemes, secured by SBLC.
                    </span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>Builder Finance and Project Finance: </strong> We specialise in arranging funding to real estate builders, small-time and SME builders. Also arranging finances to project based and start-up based companies.
                        We specialise in arranging funding to real estate builders, small-time and SME builders. Also arranging finances to project based and start-up based companies.
                    </span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>Unsecured Business Loans: </strong> We provide need based long-term business loans to business entities having more than 1 crore of turnover based on their repayment eligibility
                    </span>
                </li>
                <li className='d-flex flex-row juatify-content-start align-items-start'>
                    <i className="bi bi-check-circle"></i>
                    <span> <strong>LRD (Loan against rentals): </strong> We arrange loans from Banks/FI’s against rental income from commercial/residential properties and also special properties
                    </span>
                </li>
            </ul>
        </div>
    );

    const financial = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/consulting.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <p className='mt-3'>
                Structuring and arranging loans, preparation of project reports, preparation of loan proposals in bank CAM format,
                financial CMA preparations, financial reports, estimates and projected analysis, to be submitted to stake-holders, banks, rating agencies and others
            </p>
        </div>
    );

    const business = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/business.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <p className='mt-3'>
                Srida adds value-based relationships in terms of liaisoning with business suppliers and clients
                facilitating business opportunities and supply contracts.
            </p>
        </div>
    );

    const industrial = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/industrial.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <p className='mt-3'>
                We at Srida, provide industrial consulting services like liaisoning with govt. departments for permissions/approvals,
                TSIIC for land approvals to MSME’s, and other industrial advisory/liaisoning services.
            </p>
        </div>
    );

    const collateral = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <img src="assets/img/servicedetails/collateral.jpg" alt="" className="img-fluid services-img" style={{ width: '100%' }} />
            <h3>{head}</h3>
            <p className='mt-3'>
                We handle the property management services like valuation, legal opinion of the securities
                provided by the clients for required loan amounts.
            </p>
        </div>
    );

    return (
        <div className={`service-details-page ${isMobileNavActive && 'mobile-nav-active'}`}>

            <header id="header" className="header d-flex align-items-center position-relative">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                    <a className="logo d-flex align-items-center me-auto me-lg-0">
                        <Link to='/'>
                            <h1 className="sitename" style={{ color: '#ffc451' }}>SCG</h1>
                            <p style={{ fontSize: '7px', color: '#ffffff' }}>Srida Cosultig Group</p>
                        </Link>
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><Link to='/'>Home<br /></Link></li>
                            <li><Link to='/'>About</Link></li>
                            <li><Link to='/'>Sectors</Link></li>
                            <li><Link to='/'>Services</Link></li>
                            <li className="dropdown"><a href="#" onClick={() => setParentDropdown(!parentDropdown)}><span>Useful Links</span> <i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                <ul className={`${parentDropdown && 'dropdown-active'}`}>
                                    <li><Link to='/emicalculator'>EmiCalculator</Link></li>
                                    <li><Link to='/loaneligibility' onClick={() => { setIsMobileNavActive(!isMobileNavActive) }}>
                                        Loan Eligibility Calculator</Link></li>
                                    <li className="dropdown"><a href="#" onClick={() => setChildDropdown(!childDropdown)}><span>CheckList</span> <i
                                        className="bi bi-chevron-down toggle-dropdown"></i></a>
                                        <ul className={`${childDropdown && 'dropdown-active'}`}>
                                            <li><Link to='/checklist' state={'salaried'}>Salaried Employees</Link></li>
                                            <li><Link to='/checklist' state={'business'}>Business Entities</Link></li>
                                            <li><Link to='/checklist' state={'selfemployed'}>Self Employed</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            {/* <li><Link to='/'>Gallery</Link></li>
                            <li><Link to='/'>Team</Link></li> */}
                            <li><Link to='/'>Contact</Link></li>
                        </ul>
                        <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`}
                            onClick={mobileNavToggle}
                            ref={mobileNavToggleBtn}
                        ></i>
                    </nav>
                </div>
            </header>

            <main className="main">
                {/* Page Title */}
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Service Details</h1>
                                    <p className="mb-0">{head}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><Link to='/'>Home</Link></li>
                                <li className="current">Service Details</li>
                            </ol>
                        </div>
                    </nav>
                </div>{/* End Page Title */}

                {/* Service Details Section */}
                <section id="service-details" className="service-details section">

                    <div className="container">

                        <div className="row gy-5">

                            <div className="col-lg-4 order-2 order-lg-0" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-box">
                                    <h4>Serices List</h4>
                                    <div className="services-list">
                                        <a className={service === 'offshoring' && 'active'} onClick={() => setService('offshoring')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Off-0shoring credit under writing jobs and accounting entry works</span>
                                        </a>
                                        <a className={service === 'DebtConsulting' && 'active'} onClick={() => setService('DebtConsulting')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Debt Consulting</span>
                                        </a>
                                        <a className={service === 'Financial' && 'active'} onClick={() => setService('Financial')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Financial Consulting</span>
                                        </a>
                                        <a className={service === 'Business' && 'active'} onClick={() => setService('Business')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Business Consulting</span>
                                        </a>
                                        <a className={service === 'Industrial' && 'active'} onClick={() => setService('Industrial')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Industrial Consulting</span>
                                        </a>
                                        <a className={service === 'Collateral' && 'active'} onClick={() => setService('Collateral')} style={{ cursor: 'pointer' }}>
                                            <i className="bi bi-arrow-right-circle"></i><span>Collateral Consulting</span>
                                        </a>
                                    </div>
                                </div>{/* End Services List */}

                                {/* <div className="service-box">
                                    <h4>Download Catalog</h4>
                                    <div className="download-catalog">
                                        <a href="#"><i className="bi bi-filetype-pdf"></i><span>Catalog PDF</span></a>
                                        <a href="#"><i className="bi bi-file-earmark-word"></i><span>Catalog DOC</span></a>
                                    </div>
                                </div> */}

                                <div className="help-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-headset help-icon"></i>
                                    <h4>Have a Question?</h4>
                                    <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+91 99899 27299</span></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">admin@sridaconsulting.com</a></p>
                                </div>

                            </div>

                            {service === 'offshoring' ? (
                                offshoring()
                            ) : service === 'DebtConsulting' ? (
                                funding()
                            ) : service === 'Financial' ? (
                                financial()
                            ) : service === 'Business' ? (
                                business()
                            ) : service === 'Industrial' ? (
                                industrial()
                            ) : (
                                collateral()
                            )}

                        </div>

                    </div>

                </section>{/* /Service Details Section */}

            </main>

            {footer()}
        </div>
    )
};

export default ServiceDetails;